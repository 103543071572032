<template>
  <div id="Museums">
    <SimpleSlider img="/images/museums/slide.webp" />
    <div class="max-body-container margin">
      <TitlesComponent
        title="¡Soluciones de acceso y gestión de visitantes !"
      />
    </div>
    <AxessOffers />
    <CardsGaleryData />
    <YouChooseYourTicket />
    <YouHaveTheControl />
  </div>
</template>

<script>
import AxessOffers from '@/components/museums/AxessOffers.vue'
import CardsGaleryData from '@/components/museums/CardsGaleryData.vue'
import YouChooseYourTicket from '@/components/museums/YouChooseYourTicket.vue'
import YouHaveTheControl from '@/components/museums/YouHaveTheControl.vue'

export default {
  name: 'MuseumsView',
  components: {
    AxessOffers,
    CardsGaleryData,
    YouChooseYourTicket,
    YouHaveTheControl
  },
  mounted () {
    this.$root.toTopForce()
  }
}
</script>

<style scoped>
h3.titleGeneral {
  font-size: 25px;
}
.titleGeneral {
  max-width: 790px !important;
}
</style>
