import { render, staticRenderFns } from "./SupportYou.vue?vue&type=template&id=730385e9&scoped=true"
import script from "./SupportYou.vue?vue&type=script&lang=js"
export * from "./SupportYou.vue?vue&type=script&lang=js"
import style0 from "./SupportYou.vue?vue&type=style&index=0&id=730385e9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "730385e9",
  null
  
)

export default component.exports