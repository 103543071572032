<template>
  <div
    id="Solutions"
    class="max-body-container margin"
  >
    <v-row justify="center">
      <v-col cols="12">
        <TitlesComponent title="Soluciones para cada sector" />
      </v-col>
      <v-col
        v-for="(solution, index) in solutions"
        :key="`${solution.tool}-${index}`"
        md="3"
        cols="12"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              style="height: 100%"
              v-on="on"
            >
              <router-link
                class="buttonSolution"
                :to="solution.url"
              >
                <img
                  :alt="'Logo' + solution.tool"
                  :src="solution.img"
                  class="solution-img"
                >
                <p class="text-content textButton">
                  {{ solution.title }}
                </p>
              </router-link>
            </div>
          </template>
          <span>{{ solution.tool }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SolutionsComponent',
  data () {
    return {
      solutions: [
        {
          img: '/images/icons/arenas_icon.png',
          url: '/estadios',
          title: 'Arenas y Estadios Deportivos',
          tool: 'ir a Arenas y Estadios Deportivos'
        },
        {
          img: '/images/icons/ferias_icon.png',
          url: '/ferias',
          title: 'Ferias y Centros de Convenciones',
          tool: 'ir a Ferias y Centros de Convenciones'
        },
        {
          img: '/images/icons/museum_icon.png',
          url: '/museos',
          title: 'Museos y Atracciones Turísticas',
          tool: 'ir a Museos y Teatros'
        }
      ]
    }
  }
}
</script>

<style scoped>
.buttonSolution {
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  background-color: #e5e5e5;
  transition: 0.5s all;
  height: 100%;
}

.buttonSolution:hover {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(250, 125, 31, 0.51);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(250, 125, 31, 0.51);
  -moz-box-shadow: 0px 0px 10px 0px rgba(250, 125, 31, 0.51);
}

.solution-img {
  height: 130px;
  display: block;
  max-width: 100%;
  margin: 7px auto;
  margin-bottom: 15px;
}

.textButton {
  font-weight: bold;
  max-width: 200px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 959px) {
  .buttonSolution {
    max-width: 300px;
    margin: 0px auto;
  }
  .solution-img {
    height: 100px;
  }
}
</style>
