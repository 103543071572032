<template>
  <div
    id="RegistrationFlexibility"
    class="max-body-container margin"
  >
    <v-card
      elevation="10"
      class="card-container"
    >
      <v-row class="container-registrationFlexibility mb-0">
        <v-col
          cols="12"
          class="pb-0"
        >
          <center>
            <h2 class="titleGeneral">
              Flexibilidad en el registro de visitantes
            </h2>
            <div class="underlinedTitle" />
          </center>
          <p class="text-content text-justify mx-auto mb-0">
            Permite el registro y la compra en línea al evento desde cualqueir
            parte o si lo prefieres puedes registrarte al llegar al evento
            mediante la plataforma de AXESS VISITOR SELFREGISTRATION y comprar
            tu entrada en el punto de venta a través de Axess Smart POS.
          </p>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="pr-0 pb-0 mb-0 desktop"
        >
          <v-img
            src="/images/fairs/Axess_compra_en_linea.webp"
            height="450px"
          />
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="pl-0 pb-0 mb-0 desktop"
        >
          <v-img
            src="/images/fairs/Axess_compra_en_situ.webp"
            height="450px"
          />
        </v-col>

        <v-col
          md="6"
          cols="12"
          align-self="center"
          class="pb-0 phone"
        >
          <v-img
            src="/images/fairs/Axess_compra_en_linea.webp"
            contain
          />
        </v-col>
        <v-col
          md="6"
          cols="12"
          align-self="center"
          class="pt-0 phone"
        >
          <v-img
            src="/images/fairs/Axess_compra_en_situ.webp"
            contain
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'RegistrationFlexibility'
}
</script>

<style scoped>
.card-container {
  background-color: #fa7d1f;
  margin: 0px 12px;
  padding: 20px 0px 0px;
  overflow: hidden;
}

.text-content {
  color: white;
  max-width: 689px;
}

.phone {
  display: none;
}

.desktop {
  display: block;
}

.cubeData {
  width: fit-content;
  background-color: #e5e5e5;
  margin: 0px auto;
}

.registrationFlexibility {
  list-style: none;
}

.registrationFlexibility > li::before {
  content: '›';
  font-weight: bold;
  display: inline-block;
  margin: 2px auto;
  width: 1em;
  margin-left: -1em;
}

.titleGeneral {
  color: white;
  font-family: 'Panton';
  font-size: 28px;
  line-height: 35px;
  font-weight: normal;
  max-width: 600px;
}

.underlinedTitle {
  margin: 10px auto;
  background-color: white;
  width: 400px;
  height: 10px;
}

@media (max-width: 959px) {
  .underlinedTitle {
    width: 100%;
    max-width: 420px;
  }

  .container-registrationFlexibility {
    max-width: 500px;
    margin: 0px auto;
  }

  .phone {
    display: block;
  }

  .desktop {
    display: none;
  }
}
</style>
