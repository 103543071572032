<template>
  <div
    id="OurSolutions"
    class="max-body-container margin"
  >
    <v-row>
      <v-col cols="12">
        <TitlesComponent title="Nuestro Equipos" />
      </v-col>
      <v-col cols="12">
        <carousel-3d
          :controls-visible="true"
          :controls-width="30"
          :controls-height="60"
          :clickable="true"
          :inverse-scaling="800"
          :space="800"
          :display="3"
          :autoplay="true"
          :autoplayTimeout="3000"
          :autoplayHoverPause="true"
          :height="450"
          :width="500"
          :border="0"
          class="my-0 py-0"
        >
          <slide
            v-for="(item, i) in solutions"
            :key="i"
            :index="i"
            class="slide-equipment"
          >
            <figure class="titleFigure">
              <img
                :src="item.img"
                alt=""
                class="img-slider"
              >
              <figcaption class="titleFigCaption">
                <h2 class="bgText">
                  {{ item.title }}
                </h2>
              </figcaption>
            </figure>
          </slide>
        </carousel-3d>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  name: 'OurSolutions',
  components: {
    Carousel3d,
    Slide
  },
  data () {
    return {
      solutions: [
        {
          img: '/images/equipment/Kiosk.webp',
          url: '/estadios',
          title: 'Kiosco de Autoservicio'
        },
        {
          img: '/images/equipment/Axess_parking.webp',
          url: '',
          title: 'Control de Estacionamiento'
        },
        {
          img: '/images/equipment/SmartPOS.webp',
          url: '',
          title: 'Punto de Venta Inteligente'
        },
        {
          img: '/images/equipment/AX500_turnstile.webp',
          url: '',
          title: 'Torniquete Portátil AX500'
        },
        {
          img: '/images/equipment/AX500_ng.webp',
          url: '',
          title: 'Poste de Acceso AX500 NG'
        },
        {
          img: '/images/equipment/AX600_smart_portatil.webp',
          url: '',
          title: 'Torniquete Portátil AX600'
        }
      ]
    }
  }
}
</script>

<style scoped>
.slide-equipment {
  background-color: transparent;
}

.img-slider {
  height: 100%;
  width: auto;
  max-width: 100%;
  margin: 0px auto;
  display: block;
}

.titleFigure {
  height: calc(100% - 50px);
}

.bgText {
  text-align: center;
}

.titleFigCaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
  height: 50px;
}
</style>
