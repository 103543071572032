<template>
  <div
    id="TitlePage"
    class="max-body-container mt-6"
  >
    <v-row>
      <v-col cols="12">
        <TitlesComponent
          :title="null"
          class="mb-3"
        >
          <img
            class="icon-titlePage"
            :src="img"
            alt=""
          >
          <h2 class="titleGeneral">
            {{ title }}
            <br v-if="subtitle">
            <span
              v-if="subtitle"
              class="title-SmartGateTurnstiles"
            >
              {{ subtitle }}
            </span>
          </h2>
        </TitlesComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TitlePage',
  props: ['img', 'title', 'subtitle']
}
</script>

<style scoped>
.titleGeneral {
  max-width: 800px;
}

.title-SmartGateTurnstiles {
  font-size: 21px;
}

.icon-titlePage {
  height: 80px;
  max-width: 100%;
}
</style>
