<template>
  <div id="ArrowToTop">
    <v-scroll-y-reverse-transition>
      <v-btn
        v-show="isVisible"
        v-scroll="onScroll"
        fixed
        bottom
        right
        class="opacityClick"
        fab
        dark
        large
        color="#FF8136"
        @click="$root.toTop()"
      >
        <v-icon large>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
export default {
  name: 'ArrowToTop',
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    onScroll: function () {
      this.isVisible = window.scrollY > 300
    }
  }
}
</script>

<style scoped>
.opacityClick::before {
  opacity: 0 !important;
}
</style>
