<template>
  <div id="TitlesComponent">
    <center>
      <h2
        v-if="title != null"
        class="titleGeneral"
      >
        {{ title }}
      </h2>
      <slot v-else />
      <div class="underlinedTitle" />
    </center>
  </div>
</template>

<script>
export default {
  name: 'TitlesComponent',
  props: ['title']
}
</script>

<style scoped>
.titleGeneral {
  color: #333;
  font-family: 'Panton';
  font-size: 28px;
  line-height: 35px;
  font-weight: normal;
  max-width: 600px;
}

.underlinedTitle {
  margin: 10px auto;
  background-color: #fa7d1f;
  width: 400px;
  height: 10px;
}

@media (max-width: 959px) {
  .underlinedTitle {
    width: 100%;
    max-width: 420px;
  }
}
</style>
