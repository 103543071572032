<template>
  <div
    id="SystemDesign"
    class="max-body-container margin"
  >
    <v-row class="container-systemDesign">
      <v-col
        md="6"
        cols="12"
        align-self="center"
      >
        <TitlesComponent title="Diseño de sistemas de ferias y convenciones" />
        <strong class="text-content">Portal del expositor</strong>
        <p class="mt-3 text-content text-justify">
          Permite gestionar invitaciones, programar entregas y/o impresión de
          invitaciones, así como elaborar credenciales para los expositores y
          gestionar el cupo del evento.
        </p>
      </v-col>
      <v-col
        md="6"
        cols="12"
        align-self="center"
      >
        <v-img
          src="/images/fairs/axess_acceso_sytem_design.webp"
          contain
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SystemDesign',
  data () {
    return {}
  }
}
</script>

<style scoped>
@media (max-width: 959px) {
  .container-systemDesign {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
