<template>
  <div
    id="DontQueue"
    class="max-body-container margin"
  >
    <v-row class="container-AxessOffers">
      <v-col
        md="5"
        cols="12"
        order-md="1"
        order="12"
        align-self="center"
      >
        <v-img
          src="/images/museums/smart_leisure.webp"
          max-height="600px"
          position="50% 75%"
        />
      </v-col>
      <v-col
        md="7"
        cols="12"
        order-md="12"
        order="1"
        align-self="center"
      >
        <p
          class="text-content text-justify mx-auto"
          style="max-width=600px"
        >
          Axess, ofrece una amplia gama de hardware y software que permite
          ajustarnos a las necesidades de nuestros clientes para brindar a los
          visitantes, accesos rápidos, seguros y controlados ya sea desde la
          gestión de un parque de diversiones o un festival de música con miles
          de asistentes hasta la función de una obra de teatro, las
          funcionalidad y practicidad de nuestros equipos son la solución para
          tu negocio.
        </p>
        <TitlesComponent />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AxessOffers'
}
</script>

<style scoped>
.text-content {
  max-width: 600px;
}
@media (max-width: 959px) {
  .container-AxessOffers {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
