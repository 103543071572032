<template>
  <div
    id="LeadTrackingSystem"
    class="max-body-container margin"
  >
    <v-card
      elevation="10"
      class="card-container"
    >
      <v-img
        src="/images/fairs/lead_tracking_system.webp"
        gradient="207deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%"
      >
        <v-container>
          <v-row class="container-leadTrackingSystem">
            <v-col
              cols="12"
              class="pb-0"
            >
              <TitlesComponent title="Sistema de rastreo de leads" />
              <p class="text-content text-justify">
                La aplicación de LEADS permite desde cualquier telefono
                inteligente, llevar registro de los visitantes del stand y
                agilizar la atencion de los clientes potenciales; LEADS permite
                además calificar a los visitantes, escribir notas, enviar
                folletos y clasificar a los visitantes, todo con unos cuantos
                CLICS.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'LeadTrackingSystem'
}
</script>

<style scoped>
.text-content {
  color: black;
  text-shadow: 0px 0px 7px white;
}
.container-leadTrackingSystem {
  margin: 100px;
  max-width: 500px;
}

@media (max-width: 959px) {
  .card-container {
    margin: 0px 12px;
  }

  .container-leadTrackingSystem {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
