<template>
  <div
    id="YouHaveTheControl"
    class="max-body-container margin"
  >
    <v-row class="container-youHaveTheControl">
      <v-col
        md="7"
        cols="12"
        align-self="center"
      >
        <TitlesComponent>
          <h2 class="titleGeneral">
            Con Axess, <br>
            ¡Tú tienes el control!
          </h2>
        </TitlesComponent>
        <p class="text-content text-justify mx-auto">
          Una solución innovadora de acceso inteligente, que se adapta a las
          necesidades individuales de cada sitio, gracias a su diseño modular y
          tecnología de vanguardia, ampliables en cualquier momento.
        </p>
        <p class="text-content mb-1 mx-auto">
          El sistema nos ha permitido:
        </p>
        <ul class="text-content mx-auto">
          <li>
            Controles eficientes de los accesos, disminuyendo las
            aglomeraciones.
          </li>
          <li>Mayor control en las finanzas, evitando el robo.</li>
          <li>
            Los puntos de venta son por vía web, cajeros automáticos,
            Smartphone, handheld, etc. que nos ayudan a incrementar la
            accesibilidad a los turistas en la compra de tickets (+VTA TICKETS).
          </li>
          <li>
            El sistema se ha ajustado a las necesidades del cliente para generar
            descuentos, paquetes, comisiones, etc.
          </li>
          <li>
            Permite todo tipo de reportes de acuerdo con las exigencias y
            frecuencias necesarias que se requieran.
          </li>
          <li>
            La base de datos y el sistema cuenta con todos los niveles
            internacionales más avanzados en seguridad.
          </li>
          <li>Detección de boletos falsos e intentos de fraude.</li>
          <li>Diagnóstico de problemas de forma temprana.</li>
          <li>
            Diagnóstico en tiempo real de lo que sucede en sitio con la venta de
            boletos, permitiendo así una respuesta inmediata ante cualquier
            panorama.
          </li>
        </ul>
      </v-col>
      <v-col
        md="5"
        cols="12"
        align-self="center"
      >
        <v-img
          src="/images/museums/unforgettable.webp"
          max-height="640px"
          position="50% 85%"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'YouHaveTheControl'
}
</script>

<style scoped>
.text-content {
  max-width: 600px;
}
@media (max-width: 959px) {
  .container-youHaveTheControl {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
