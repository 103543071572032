<template>
  <div
    id="WhereDoWeMeet"
    class="max-body-container margin"
  >
    <v-row justify="center">
      <v-col cols="12">
        <TitlesComponent :title="null">
          <h2 class="titleGeneral">
            Donde nos encontramos <br>
            (estadios y arenas en Estados Unidos)
          </h2>
        </TitlesComponent>
      </v-col>
      <v-col
        v-for="(place, index) in WhereDoWeMeetData"
        :key="`${place.tool}-${index}`"
        lg="3"
        md="6"
        cols="12"
        class="col-place"
      >
        <div class="container-place">
          <v-img
            :src="'/images/stadiums/whereDoWeMeet/' + place.image"
            class="img-place"
          />
          <div class="mt-3 container-description-place">
            <img
              :src="'/images/stadiums/whereDoWeMeet/' + place.logo"
              class="logo-place my-0"
            >
            <p
              class="description-place text-center my-0"
              v-html="place.description"
            />
            <img
              v-if="place.logo2"
              :src="'/images/stadiums/whereDoWeMeet/' + place.logo2"
              class="logo-place my-0"
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'WhereDoWeMeet',
  data () {
    return {
      WhereDoWeMeetData: [
        {
          image: 'lumen_field.webp',
          logo: 'lumen_field_logo.webp',
          description:
            'Lumen Field <br/> Seattle Seahawks and Seattle Sounders FC',
          logo2: 'lumen_field_logo1.webp'
        },
        {
          image: 'alamodome.webp',
          logo: 'alamodome_logo.webp',
          description: 'Alamodome <br/> Mixed Use',
          logo2: ''
        },
        {
          image: 'chesapeake_energy_arena.webp',
          logo: 'chesapeake_energy_arena_logo.webp',
          description: 'Chesapeake Energy Arena <br/> Oklahoma City Thunder',
          logo2: ''
        },
        {
          image: 'yankees_stadium.webp',
          logo: 'yankees_stadium_logo.webp',
          description: 'Yankees Stadium <br/> New York Yankees',
          logo2: ''
        }
      ]
    }
  }
}
</script>

<style scoped>
.container-place {
  height: 100%;
}

.container-description-place {
  display: flex;
  padding-left: 15px;
  gap: 20px;
  height: 80px;
}

.col-place:nth-child(2) .container-description-place {
  justify-content: space-evenly;
  align-items: center;
  padding-left: 0px;
  gap: 5px;
}

.logo-place {
  width: auto;
  max-height: 50px;
  width: auto;
  max-width: 70px;
}

.col-place:nth-child(3) .logo-place {
  max-width: 90px;
}

.img-place {
  height: 250px;
  width: 100%;
}
@media (max-width: 1400px) {
  .container-description-place {
    height: 120px;
  }
}

@media (max-width: 1263px) {
  .col-place {
    margin-top: 20px;
  }

  .col-place:nth-child(2),
  .col-place:nth-child(3) {
    margin-top: 0px;
  }

  .container-description-place {
    max-width: 350px;
    margin: auto;
    height: auto;
    padding-left: 0px;
    align-content: center;
  }

  .img-place {
    margin: auto;
    height: 300px;
    width: 350px;
  }
}
</style>
